<template>
    <v-data-table
        disable-sort
        disable-pagination
        hide-default-footer
        :items-per-page="100"
        :mobile-breakpoint="0"
        :headers="tableHeaders"
        :items="data"
    >
        <template v-slot:item="{item, headers}">
            <tr>
                <td
                    :class="getColumnClasses(header, item)"
                    :key="index" v-for="(header, index) in headers"
                >
                    {{ item[header.value] }}
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        name: "TxYtdPerfTable",
        props: [
            'data'
        ],
        data(){
            return {
                tableHeaders: [
                    {text: 'Month', value: 'month', width: "120"},
                    {text: 'Beginning Balance', value: 'open_balance', align: 'end'},
                    {text: 'Ending Balance', value: 'close_balance', align: 'end'},
                    {text: 'Net Additions', value: 'additions', align: 'end'},
                    {text: 'Net Subtractions', value: 'subtractions', align: 'end'},
                    {text: 'Change in Investment Value', value: 'bal_change', align: 'end', width: "200"},
                    {text: 'Monthly Return %', value: 'twrMonth', align: 'end', width: "140"},
                    {text: 'YTD TWR %', value: 'twrYear', align: 'end', width: "140"},
                ]
            }
        },
        methods: {
            getHeaderClass(header){
                return  header.align ? `text-${header.align}` : ''
            },
            getColorClass(header, item){

                if(['bal_change', 'twrMonth', 'twrYear'].includes(header.value)){
                    let itemVal = item[header.value];
                    return itemVal[0] === '-' ? 'negative-num' : 'positive-num'
                }

                return ''

            },
            getColumnClasses(header, item){

                let headerClass = this.getHeaderClass(header);
                let colorClass = this.getColorClass(header, item);
                return [headerClass, colorClass].join(' ');

            }
        },
    }
</script>

<style scoped>
.positive-num{
    color: #4CAF50;
}

.negative-num{
    color: #F44336;
}
</style>