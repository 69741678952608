<template>
  <v-data-table
    disable-sort
    disable-pagination
    hide-default-footer
    :headers="tableHeaders"
    :items="formattedTableData"
    :mobile-breakpoint="0"
    no-data-text="No data for this period. Please select an individual group or account to view historical performance."
  >
    <template v-slot:item.return="{ item }">
      <span :class="getValueClass(item.return)" v-text="item.return"></span>
    </template>
  </v-data-table>
</template>

<script>
import { sortBy } from "../../utils/data.utils";

export default {
  name: "TxHistoricalTable",
  props: ["tableData"],
  methods: {
    getValueClass(value) {
      return value[0] === "-" ? "negative-num" : "positive-num";
    }
  },
  data() {
    return {
      tableHeaders: [
        { text: "Year", value: "year" },
        { text: "Annual Return", value: "return", align: "end" }
      ],
      records: [
        { year: 2017, return: "20.01%" },
        { year: 2018, return: "-20.01%" },
        { year: 2019, return: "20.01%" }
      ]
    };
  },
  computed: {
    formattedTableData() {
      let sortFunc = sortBy("year");

      let sortedData = this.tableData.map(x => x).sort(sortFunc);

      let newRecords = [];

      sortedData.forEach(record => {
        newRecords.push({
          year: record.year,
          return: (record.performance * 100).toFixed(2) + "%"
        });
      });

      return newRecords;
    }
  }
};
</script>

<style scoped>
.positive-num {
  color: #4caf50;
}

.negative-num {
  color: #f44336;
}
</style>
