<template>
    <v-data-table
        disable-sort
        disable-pagination
        hide-default-header
        hide-default-footer
        :headers="tableHeaders"
        :items="formattedTableData"
        class="mt-5"
    >
        <template v-slot:item="{ item }">
            <tr>
                <td :class="getTextClasses(item)">{{ item.text }}</td>
                <td class="text-right" :class="getValueClasses(item)">{{ item.value }}</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    import { currencyFormatter } from "../../utils/format.utils";
    import { sortBy } from "../../utils/data.utils";
    import { mapState } from 'vuex'

    export default {
        name: "TxYtdSummaryTable",
        props: [
            'tableData'
        ],
        data(){
            return {
                tableHeaders: [
                    {text: '', value: 'text'},
                    {text: '', value: 'value', align: 'end'},
                ]
            }
        },
        methods: {
            getColumnClasses(item){

                let totalClasses = ((item.text.toLowerCase().startsWith('beginning')) || (item.text.toLowerCase().startsWith('adjusted asset'))) ? 'total-bar' : 'not-total-bar';
                if (!['subtractions', 'additions'].includes(item.text.toLowerCase())) totalClasses += ' total-field';
                return totalClasses
            },
            getTextClasses(item){
                let totalClasses = this.getColumnClasses(item);

                totalClasses += ['subtractions', 'additions'].includes(item.text.toLowerCase()) ? ' indent-item' : '';

                return totalClasses
            },
            getValueClasses(item){
                let totalClasses = this.getColumnClasses(item);

                if(['adjusted asset value', 'return %'].includes(item.text.toLowerCase())){
                    totalClasses += item.value[0] === '-' ? ' negative-num' : ' positive-num'
                }

                return totalClasses
            }
        },
        computed: {
            ...mapState(['monthNumMap']),
            sortedTableData() {
                const newRecords = []

                this.tableData.forEach(record => {
                    newRecords.push({
                        ...record,
                        sortDate: new Date(record.date)
                    })
                })

                const sortFunc = sortBy('sortDate')

                return newRecords.sort(sortFunc)
            },
            firstRecord(){
                return this.sortedTableData.length > 0 ? this.sortedTableData[0] : {}
            },
            lastRecord(){
                return this.sortedTableData.length > 0 ? this.sortedTableData.slice(-1)[0] : {}
            },
            startBalanceRecord(){
                let startBalance = currencyFormatter.format(this.firstRecord.open_balance);
                let startDate = `${this.monthNumMap[this.firstRecord.month]} ${this.firstRecord.year}`;
                return {
                    text: `Beginning Market Value - ${startDate}`,
                    value: startBalance
                }
            },
            allAdditions(){

                let allAdditions = 0;
                this.tableData.forEach(record => {
                    allAdditions += Math.abs(record.additions_dollars)
                });

                return allAdditions

            },
            allSubtractions(){

                let allSubtractions = 0;
                this.tableData.forEach(record => {
                    allSubtractions += Math.abs(record.subtractions_dollars)
                });

                return allSubtractions
            },
            netCashFlowsRecord(){

                let netCashFlows = this.allAdditions - (this.allSubtractions);
                return {
                    text: 'Net Cash Flows',
                    value: currencyFormatter.format(netCashFlows)
                }

            },
            subtractionsRecord(){

                return {
                    text: 'Subtractions',
                    value: currencyFormatter.format(this.allSubtractions)
                }

            },
            additionsRecord(){

                return {
                    text: 'Additions',
                    value: currencyFormatter.format(this.allAdditions)
                }

            },
            adjustedAssetValueRecord(){

                let adjAssetValue = this.lastRecord.close_balance - this.firstRecord.open_balance - this.allAdditions + this.allSubtractions;
                return {
                    text: 'Adjusted Asset Value',
                    value: currencyFormatter.format(adjAssetValue)
                }

            },
            endBalanceRecord(){
                let endBalance = currencyFormatter.format(this.lastRecord.close_balance);
                let endDate = `${this.monthNumMap[this.lastRecord.month]} ${this.lastRecord.year}`;
                return {
                    text: `Ending Market Value - ${endDate}`,
                    value: endBalance
                }
            },
            returnRecord(){

                let returnPct = (this.lastRecord.twr_year * 100).toFixed(2).toString() + '%';
                return {
                    text: 'Return %',
                    value: returnPct
                }

            },
            formattedTableData(){

                let tableData = [];

                tableData.push(this.startBalanceRecord);
                tableData.push(this.netCashFlowsRecord);
                tableData.push(this.subtractionsRecord);
                tableData.push(this.additionsRecord);
                tableData.push(this.adjustedAssetValueRecord);
                tableData.push(this.endBalanceRecord);
                tableData.push(this.returnRecord);

                return tableData

            }
        }
    }
</script>

<style scoped>
.total-bar{
        border-bottom: 1px solid black !important;
    }

.not-total-bar{
    border-bottom: none !important
}

.total-field{
    font-weight: bold;
}

.indent-item{
    padding-left: 50px;
}

.positive-num{
    color: #4CAF50;
}

.negative-num{
    color: #F44336;
}
</style>