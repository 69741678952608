<template>
  <div class="chart-box">
    <canvas id="by-account-perf-line"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import { sortBy } from "../../utils/data.utils";
import { mapState } from "vuex";
import { getUserDomain } from "../../utils/domains.utils";
import themes from "../../themes";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "TxYtdLine",
  props: ["data"],
  watch: {
    sortedData: function() {
      this.computeChart();
    }
  },
  methods: {
    hexToRGBA(hex, alpha = 1) {
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        let c = hex.substring(1).split("");
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = "0x" + c.join("");
        return (
          "rgba(" +
          [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
          `,${alpha})`
        );
      }
      throw new Error("Bad Hex");
    },
    computeChart() {
      let ctx = document.getElementById("by-account-perf-line");
      this.chart = new Chart(ctx, {
        plugins: [ChartDataLabels],
        type: "line",
        data: {
          labels: this.chartFormattedLabels,
          datasets: [
            {
              cubicInterpolationMode: "monotone",
              lineTension: 0,
              label: "Accumulated Return % by end of Month",
              backgroundColor: this.hexToRGBA(this.colors.primary, 0.2),
              borderColor: this.hexToRGBA(this.colors.primary),
              pointBackgroundColor: this.hexToRGBA(this.colors.primary),
              pointBorderColor: this.hexToRGBA(this.colors.primary),
              fill: "origin",
              data: this.chartFormattedData
            }
          ]
        },
        options: {
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                let dataset = data.datasets[tooltipItem.datasetIndex];
                let currentValue = dataset.data[tooltipItem.index];
                return (currentValue * 100).toFixed(2).toString() + "%";
              }
            }
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function(value) {
                    return (value * 100).toFixed(2) + "%";
                  }
                }
              }
            ],
            xAxes: [
              {
                ticks: {
                  padding: 25
                }
              }
            ]
          },
          plugins: {
            datalabels: {
              color: function(context) {
                return context.dataset.data[context.dataIndex] < 0
                  ? "#F44336"
                  : "#4CAF50";
              },
              clamp: true,
              anchor: "start",
              align: function(context) {
                let biggerThanLast =
                  context.dataset.data[context.dataIndex] >=
                  context.dataset.data[context.dataIndex - 1];
                return biggerThanLast ? "end" : "start";
              },
              formatter: function(value, context) {
                return context.dataIndex === context.dataset.data.length - 1
                  ? (value * 100).toFixed(2) + "%"
                  : "";
              }
            }
          }
        }
      });
    }
  },
  computed: {
    ...mapState(["monthNumMap"]),
    sortedData() {
      let sortFunc = sortBy("year", "month");
      return this.data.map(x => x).sort(sortFunc);
    },
    chartFormattedData() {
      let chartData = [0];
      this.sortedData.forEach(record => {
        chartData.push(record.twr_year);
      });
      return chartData;
    },
    chartFormattedLabels() {
      let firstDate = `${this.sortedData[0].year} Start`;
      let chartLabels = [firstDate];
      this.sortedData.forEach(record => {
        let label = `${this.monthNumMap[record.month]} ${record.year}`;
        chartLabels.push(label);
      });
      return chartLabels;
    },
    colors() {
      const domain = getUserDomain();
      return themes[domain] || themes["activest"];
    }
  }
};
</script>

<style scoped></style>
