<template>
    <report-summary-card
        report-title="Historical Performance"
        :loading="byAccountPerfLoading"
        report-link=""
    >
        <template v-slot:table>
            <tx-historical-table :table-data="data">

            </tx-historical-table>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import {mapGetters} from 'vuex'
    import TxHistoricalTable from './TxHistoricalTable'

    export default {
        name: "TxHistoricalCard",
        props: [
            'data', 'id'
        ],
        components: {
            ReportSummaryCard, TxHistoricalTable
        },
        computed: {
            ...mapGetters(['byAccountPerfLoading'])
        }
    }
</script>

<style scoped>

</style>