<template>
  <div>
    <v-container class="a-container">
      <div
        :class="mobileCentering"
        class="display-1 primary-text font-weight-bold mb-2 mt-3"
      >
        Performance
      </div>
      <v-divider></v-divider>
      <v-row v-if="!isMobile" class="mt-3" :justify="mobileFilterCentering">
        <v-col cols="12" sm="3">
          <clearable-chips-selector
            label="Group"
            v-model="selectedGroupFilter"
            :items="groups"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12" sm="3">
          <clearable-chips-selector
            label="Manager"
            v-model="selectedManagerFilter"
            :items="managers"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12" sm="3">
          <clearable-chips-selector
            :items="accountIds"
            label="Account"
            v-model="selectedAccountFilter"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12" sm="3">
          <clearable-chips-selector
            :items="banks"
            label="Custodian"
            v-model="selectedBankFilter"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12" sm="3">
          <v-select
            :items="dateChoices"
            v-model="dateChoice"
            label="Date Range"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="3">
          <tx-ytd-summary-card :data="data.ytdPerformanceData">
          </tx-ytd-summary-card>
        </v-col>
        <v-col cols="12" lg="6">
          <tx-ytd-line-card :data="data.ytdPerformanceData"> </tx-ytd-line-card>
        </v-col>
        <v-col cols="12" lg="3">
          <tx-historical-card :data="data.historicalPerformanceData">
          </tx-historical-card>
        </v-col>
        <v-col cols="12">
          <tx-ytd-perf-table-card :data="data.ytdPerformanceData">
          </tx-ytd-perf-table-card>
        </v-col>
        <v-col cols="12" lg="6">
          <as-of-table-card
            :loading="data.statusByCustodianStatus"
            :table-data="data.statusByCustodianData"
          ></as-of-table-card>
        </v-col>
      </v-row>
    </v-container>
    <mobile-filter-pane>
      <template v-slot:filters>
        <v-col cols="12">
          <clearable-chips-selector
            label="Group"
            v-model="selectedGroupFilter"
            :items="groups"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12">
          <clearable-chips-selector
            label="Manager"
            v-model="selectedManagerFilter"
            :items="managers"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12">
          <clearable-chips-selector
            :items="accountIds"
            label="Account"
            v-model="selectedAccountFilter"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12">
          <clearable-chips-selector
            :items="banks"
            label="Custodian"
            v-model="selectedBankFilter"
          >
          </clearable-chips-selector>
        </v-col>
      </template>
    </mobile-filter-pane>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import TxYtdLineCard from "../components/reports/TxYtdLineCard";
import TxHistoricalCard from "../components/reports/TxHistoricalCard";
import TxYtdSummaryCard from "../components/reports/TxYtdSummaryCard";
import TxYtdPerfTableCard from "../components/reports/TxYtdPerfTableCard";
import MobileFilterPane from "../components/base/filters/MobileFilterPane";
import AsOfTableCard from "../components/reports/AsOfTableCard";
import ClearableChipsSelector from "../components/base/filters/ClearableChipsSelector";

export default {
  name: "Performance",
  components: {
    TxYtdLineCard,
    TxHistoricalCard,
    TxYtdSummaryCard,
    TxYtdPerfTableCard,
    MobileFilterPane,
    AsOfTableCard,
    ClearableChipsSelector
  },
  data() {
    return {
      consPosCharts: ["statusByCustodian"],
      byAccountCharts: ["ytdPerformance", "historicalPerformance"],
      data: {
        ytdPerformanceData: [],
        ytdPerformanceStatus: "loading",
        historicalPerformanceData: [],
        historicalPerformanceStatus: "loading",
        statusByCustodianData: [],
        statusByCustodianStatus: "loading"
      },
      startDateFilter: this.getFirstDayOfYear(),
      endDateFilter: this.getToday(),
      dateChoices: [
        { text: "YTD", value: "ytd" },
        { text: "Previous Year", value: "previous" }
      ],
      dateChoice: "ytd"
    };
  },
  computed: {
    ...mapGetters([
      "accountIds",
      "groups",
      "managers",
      "banks",
      "byAccountPerfLoading",
      "statusByBank"
    ]),
    ...mapState([
      "userByAccountPerformanceRecords",
      "yearlyPerformance",
      "groupFilter",
      "managerFilter",
      "accountFilter",
      "bankFilter",
      "groupAccountMap"
    ]),
    filters() {
      return {
        group: this.groupFilter,
        manager: this.managerFilter,
        account: this.accountFilter,
        custodian: this.bankFilter,
        start_date: this.startDateFilter,
        end_date: this.endDateFilter
      };
    },
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    mobileCentering() {
      return this.isMobile ? "text-center" : "";
    },
    mobileFilterCentering() {
      return this.isMobile ? "center" : null;
    },
    selectedGroupFilter: {
      set(groups) {
        this.$store.commit("setGroupFilter", groups);
      },
      get() {
        return this.groupFilter;
      }
    },
    selectedManagerFilter: {
      set(managers) {
        this.$store.commit("setManagerFilter", managers);
      },
      get() {
        return this.managerFilter;
      }
    },
    selectedAccountFilter: {
      set(accounts) {
        this.$store.commit("setAccountFilter", accounts);
      },
      get() {
        return this.accountFilter;
      }
    },
    selectedBankFilter: {
      set(banks) {
        this.$store.commit("setBankFilter", banks);
      },
      get() {
        return this.bankFilter;
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        console.log("filters");
        console.log(this.selectedAccountFilter);
        console.log(this.selectedManagerFilter);
        console.log(this.selectedGroupFilter);
        console.log(this.selectedBankFilter);
        this.getFilteredData();
      }
    },
    dateChoice(val) {
      if (val === "ytd") {
        this.startDateFilter = this.getFirstDayOfYear();
        this.endDateFilter = this.getToday();
      } else if (val === "previous") {
        this.startDateFilter = this.getFirstDayOfPreviousYear();
        this.endDateFilter = this.getLastDayOfPreviousYear();
      }
    }
  },
  mounted() {
    this.getFilteredData();
  },
  methods: {
    ...mapActions(["getByAccountPerformanceData", "getConsPosData"]),
    getFilteredData() {
      console.log("called");
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getAllConsPosData(this.consPosCharts);
        this.getAllByAccountPerfData(this.byAccountCharts);
      }, 500);
    },
    getAllConsPosData(charts) {
      charts.forEach(async chart => {
        this.data[chart + "Status"] = true;
        try {
          const postParams = {
            chart,
            viewBy: "category",
            ...this.filters,
            paginate: false
          };
          this.data[chart + "Data"] = await this.getConsPosData(postParams);
          this.data[chart + "Status"] = false;
        } catch (e) {
          this.data[chart + "Status"] = false;
        }
      });
    },
    getAllByAccountPerfData(charts) {
      charts.forEach(async chart => {
        this.data[chart + "Status"] = "loading";
        try {
          this.data[chart + "Data"] = await this.getByAccountPerformanceData({
            chart,
            viewBy: "category",
            ...this.filters
          });
          this.data[chart + "Status"] = "loaded";
        } catch (e) {
          console.log(e);
          this.data[chart + "Status"] = "error";
        }
      });
    },
    getFirstDayOfYear() {
      const today = new Date();
      const year = today.getFullYear();
      return `${year}-01-01`;
    },
    getToday() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    getPreviousYear() {
      const today = new Date();
      return today.getFullYear() - 1;
    },
    getFirstDayOfPreviousYear() {
      const previousYear = this.getPreviousYear();
      return `${previousYear}-01-01`;
    },
    getLastDayOfPreviousYear() {
      const previousYear = this.getPreviousYear();
      return `${previousYear}-12-31`;
    }
  }
};
</script>

<style scoped></style>
