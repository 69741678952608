<template>
    <report-summary-card
        report-title="YTD Performance Details"
        :loading="byAccountPerfLoading"
        report-link=""
        show-export
        :export-data="exportData"
        export-file-name="ytd_performance_details.csv"
    >
        <template v-slot:table>
            <tx-ytd-perf-table
                :data="tableDataFormatted"
            ></tx-ytd-perf-table>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import TxYtdPerfTable from './TxYtdPerfTable'
    import {mapGetters, mapState} from 'vuex'
    import {sortBy} from "../../utils/data.utils";
    import {currencyFormatter} from "../../utils/format.utils";

    export default {
        name: "TxYtdPerfTableCard",
        props: [
            'data', 'id'
        ],
        components: {
            ReportSummaryCard, TxYtdPerfTable
        },
        data() {
            return {

            }
        },
        computed: {
            ...mapState(['monthNumMap']),
            ...mapGetters(['byAccountPerfLoading']),
            tableDataSorted(){
                let newData = this.data.map(x => x);
                let sortFunc = sortBy('year', 'month');
                return newData.sort(sortFunc)
            },
            tableDataFormatted(){

                let newData = [];
                this.tableDataSorted.forEach(record => {

                    let formattedRecord = {};

                    formattedRecord.month = `${this.monthNumMap[record.month]} ${record.year}`;
                    formattedRecord.open_balance = currencyFormatter.format(record.open_balance);
                    formattedRecord.close_balance = currencyFormatter.format(record.close_balance);
                    formattedRecord.additions = currencyFormatter.format(record.additions_dollars);
                    formattedRecord.subtractions = currencyFormatter.format(record.subtractions_dollars);
                    formattedRecord.twrYear = (record.twr_year * 100).toFixed(2).toString() + '%';
                    formattedRecord.twrMonth = (record.twr_month * 100).toFixed(2).toString() + '%';
                    let change = record.close_balance - record.open_balance - Math.abs(record.additions_dollars) + Math.abs(record.subtractions_dollars);
                    formattedRecord.bal_change = currencyFormatter.format(change);

                    newData.push(formattedRecord)

                });

                return newData
            },
            exportData(){
                let exportData = []
                this.tableDataSorted.forEach(o => {
                    const newObject = {
                        'Month': `${this.monthNumMap[o.month]} ${o.year}`,
                        'Beginning Balance': o.open_balance.toFixed(2),
                        'Ending Balance': o.close_balance.toFixed(2),
                        'Net Additions': o.additions_dollars.toFixed(2),
                        'Net Subtractions': o.subtractions_dollars.toFixed(2),
                        'Change in Investment Value': o.bal_change.toFixed(2),
                        'Monthly Return %': o.twr_month.toFixed(4),
                        'YTD TWR %': o.twr_year.toFixed(4)
                    }
                    exportData.push(newObject)
                })
                return exportData
            }
        }
    }
</script>

<style scoped>

</style>