<template>
    <report-summary-card
        report-title="YTD Monthly Performance"
        :loading="byAccountPerfLoading"
        report-link=""
    >
        <template v-slot:chart>
            <tx-ytd-line
                :data="data"
            ></tx-ytd-line>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import {mapGetters} from 'vuex'
    import TxYtdLine from './TxYtdLine'

    export default {
        name: "TxYtdLineCard",
        props: [
            'data', 'id'
        ],
        components: {
            ReportSummaryCard, TxYtdLine
        },
        computed: {
            ...mapGetters(['byAccountPerfLoading'])
        }
    }
</script>

<style scoped>

</style>